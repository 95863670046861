<template>
  <div>
    <v-chip
      v-if="ffijada"
      class="ml-1 mt-1"
      small
      color="red"
      label
      dark
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.calendar }}
      </v-icon>
      Fijado {{ ffijada|shortDateTime }}
    </v-chip>
    <v-chip
      v-if="avisarAntesIr"
      class="ml-1 mt-1"
      small
      color="warning"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.phone }}
      </v-icon>
      Avisar antes de ir
    </v-chip>
    <v-chip
      v-if="!manoDeObraFacturable"
      class="ml-1 mt-1"
      small
      color="error"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.manoDeObra }}
      </v-icon>
      Mano de obra NO facturable
    </v-chip>
    <v-chip
      v-if="idtfacturacionKilometros === TFACTURACION_KILOMETROS.ids.noFacturable"
      class="ml-1 mt-1"
      small
      color="error"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.desplazamiento }}
      </v-icon>
      Desplazamiento NO facturable
    </v-chip>
    <v-chip
      v-if="tmotivoBloqueo && tmotivoBloqueo.descripcion"
      class="ml-1 mt-1"
      small
      color="error"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.motivoBloqueo }}
      </v-icon>
      {{ tmotivoBloqueo.descripcion }}
    </v-chip>
    <v-chip
      v-if="cantAccionesTotal > 0"
      class="ml-1 mt-1"
      small
      color="success"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.accionMaterial }}
      </v-icon>
      {{ cantAccionesRealizadas }} de {{ cantAccionesTotal }} acciones realizadas
    </v-chip>
    <v-chip
      v-if="proximasRevisiones.length > 0"
      class="ml-1 mt-1"
      small
      :color="proximaRevision.color"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.revision }}
      </v-icon>
      {{ proximaRevision.tactuacion.descripcion }} ({{ proximaRevision.periodo_revision.descripcion }}) - {{ proximaRevision.sistema_mant.fplanificacion|shortDate }}
    </v-chip>
    <v-chip
      v-if="cantidadNotas > 0"
      class="ml-1 mt-1 align-items-center"
      small
      color="info"
      label
      @click="clickChip('notas')"
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.nota }}
      </v-icon>
      Con notas
    </v-chip>
  </div>
</template>

<script>
import _ from '@/utils/lodash'
import { TFACTURACION_KILOMETROS } from '@/utils/consts'
import { currentDate, toDate } from '@/utils/date'

export default {
  props: {
    ffijada: {
      type: Date,
      default: function () { return new Date() },
    },
    avisarAntesIr: {
      type: Boolean,
    },
    manoDeObraFacturable: {
      type: Boolean,
    },
    idtfacturacionKilometros: {
      type: Number,
      default: 0,
    },
    cantAccionesRealizadas: {
      type: Number,
      default: 0,
    },
    cantAccionesTotal: {
      type: Number,
      default: 0,
    },
    tmotivoBloqueo: {
      type: Object,
      default: () => {},
    },
    proximasRevisiones: {
      type: Array,
      default: () => [],
    },
    cantidadNotas: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      TFACTURACION_KILOMETROS,
    }
  },
  computed: {
    proximaRevision () {
      let revision = {}
      if (this.proximasRevisiones.length > 0) {
        revision = _.cloneDeep(this.proximasRevisiones[0])
        if (toDate(revision.sistema_mant.fplanificacion) < currentDate()) {
          revision.color = 'error'
        } else {
          revision.color = 'info'
        }
      }
      return revision
    },
  },
  methods: {
    clickChip (name) {
      this.$emit('click-chip', name)
    },
  }
}
</script>
